import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";

const h2Style = "text-lg lg:text-2xl uppercase text-center font-extrabold mt-8";

const Services = () => (
  <div className="hidden w-full h-full max-w-screen-xl md:flex items-start justify-center px-6 xl:px-0 mx-auto">
    <div>
      <StaticImage
        src="../../images/services-thumb-1.png"
        width={362}
        quality={95}
        placeholder="tracedSVG"
        formats={["AUTO", "WEBP", "AVIF"]}
        alt="Online Payment Integration"
      />
      <h2 className={h2Style}>
        Online Payment
        <br /> Integration
      </h2>
    </div>

    <div>
      <StaticImage
        src="../../images/services-thumb-2.png"
        width={362}
        quality={95}
        placeholder="tracedSVG"
        formats={["AUTO", "WEBP", "AVIF"]}
        alt="POS & EFTPOS Integration"
      />
      <h2 className={h2Style}>
        POS & EFTPOS
        <br /> Integration
      </h2>
    </div>

    <div>
      <StaticImage
        src="../../images/services-thumb-3.png"
        width={362}
        quality={95}
        placeholder="tracedSVG"
        formats={["AUTO", "WEBP", "AVIF"]}
        alt="Accounting, CRM & ERP Software Integration"
      />
      <h2 className={h2Style}>
        Accounting, CRM & ERP
        <br /> Software Integration
      </h2>
    </div>
  </div>
);

export default Services;
