import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "react-scroll";

const ServicePOS = () => (
  <div className="relative w-full mt-32 xl:mt-0">
    <div className="w-full max-w-screen-xl flex items-start justify-center mx-auto mb-24 px-6 xl:px-14">
      <div className="flex flex-col-reverse lg:flex-row items-center">
        <div className="w-full lg:w-1/2 xl:pl-6 2xl:pl-0">
          <h2 className="text-3xl md:text-5xl font-bold mb-12 md:mb-16">
            POS & EFTPOS Integration
          </h2>
          <p className="text-lg md:text-xl leading-relaxed md:leading-loose md:px-2">
            We create intuitive customised POS software integrated seamlessly
            with your EFTPOS and POS hardware that both your customers and staff
            will love using. No matter the industry you're in, we can help!
          </p>
          <h3 className="text-lg uppercase font-bold mt-12 md:px-2">
            <Link
              to="contact"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
            >
              <span className="text-site-blue-dark cursor-pointer">
                Contact us
              </span>
            </Link>
            {` `}
            to find out how.
          </h3>
        </div>
        <div className="w-full lg:w-1/2 pb-6 md:px-12 xl:p-6">
          <StaticImage
            src="../../images/pos-eftpos-integration.png"
            width={651}
            quality={95}
            placeholder="tracedSVG"
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="POS & EFTPOS Integration"
          />
        </div>
      </div>
    </div>
  </div>
);

export default ServicePOS;
