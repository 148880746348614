// Using Netlify Forms https://www.netlify.com/products/forms/

import * as React from "react";

const inputStyle =
  "w-full border rounded-md bg-site-gray-light focus:bg-white text-site-gray-dark focus:text-gray-900 focus:outline-none focus:ring-site-blue-dark focus:border-site-blue-dark text-lg py-5 px-7";

const ContactForm = () => (
  <form
    method="post"
    netlify-honeypot="bot-field"
    data-netlify="true"
    name="contact"
    className="xl:mr-6"
  >
    <input type="hidden" name="bot-field" />
    <input type="hidden" name="form-name" value="contact" />

    <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
      <div className="w-full">
        <label htmlFor="name" className="sr-only">
          Your name
        </label>
        <input
          type="text"
          name="name"
          placeholder="Your name"
          className={inputStyle}
          required
        />
      </div>
      <div className="w-full">
        <label htmlFor="email" className="sr-only">
          Email address
        </label>
        <input
          type="email"
          name="email"
          placeholder="Email address"
          className={inputStyle}
          required
        />
      </div>
      <div className="w-full">
        <label htmlFor="subject" className="sr-only">
          Subject
        </label>
        <input
          type="text"
          name="subject"
          placeholder="Subject"
          className={inputStyle}
          required
        />
      </div>
      <div className="w-full">
        <label htmlFor="phone" className="sr-only">
          Phone number
        </label>
        <input
          type="tel"
          name="phone"
          placeholder="Phone number"
          className={inputStyle}
          required
        />
      </div>
    </div>
    <div className="w-full mt-3">
      <label htmlFor="message" className="sr-only">
        Message
      </label>
      <textarea
        name="message"
        rows={6}
        placeholder="Write message"
        className={inputStyle}
        required
      />
    </div>
    <button className="border-2 border-site-blue-dark text-bold text-site-blue-dark hover:text-white bg-white hover:bg-site-blue-dark mt-3 mb-4">
      Send Message
    </button>
  </form>
);

export default ContactForm;
