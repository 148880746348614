import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import styled from "styled-components";

import ContactForm from "../contact-form";

const StyledDiv = styled.div`
  min-height: 600px;
`;

const Contact = () => (
  <StyledDiv className="relative w-full mt-12 mb-32">
    <div className="hidden lg:block lg:w-1/2 xl:w-full absolute top-0 left-0 xl:-ml-64 2xl:ml-0 lg:mt-32 xl:-mt-20 -z-10">
      <StaticImage
        src="../../images/contact-bg.png"
        width={879}
        quality={95}
        placeholder="tracedSVG"
        formats={["AUTO", "WEBP", "AVIF"]}
        alt=""
      />
    </div>

    <div className="lg:hidden w-full flex justify-center">
      <StaticImage
        src="../../images/mobile-contact-img.png"
        width={624}
        quality={95}
        placeholder="tracedSVG"
        formats={["AUTO", "WEBP", "AVIF"]}
        alt=""
      />
    </div>

    <div id="contact" className="w-full max-w-screen-xl mx-auto px-6 xl:px-12">
      <div className="flex items-center justify-end">
        <div className="w-full lg:w-1/2">
          <h2 className="text-5xl font-bold mt-24 mb-12">Contact Us</h2>
          <ContactForm />
        </div>
      </div>
    </div>
  </StyledDiv>
);

export default Contact;
