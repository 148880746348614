import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import styled from "styled-components";

const StyledDiv = styled.div`
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.1);
`;

const h2Style = "text-2xl uppercase text-center font-extrabold mt-8";
const pStyle = "text-lg text-center leading-loose mt-6 px-2";

const SecurityFeatures = () => (
  <div className="w-full max-w-screen-xl grid grid-cols-1 lg:grid-cols-3 gap-16 lg:gap-6 mx-auto md:mt-16 mb-24 px-6 md:px-32 lg:px-6 xl:px-16">
    <StyledDiv className="flex flex-col items-center rounded-md pt-8 pb-12 px-4">
      <StaticImage
        src="../../images/device-finger-printing.png"
        width={250}
        quality={95}
        placeholder="tracedSVG"
        formats={["AUTO", "WEBP", "AVIF"]}
        alt="Device Finger Printing"
      />
      <h2 className={h2Style}>Device Finger Printing</h2>
      <p className={pStyle}>
        Device fingerprinting enables us to identify and block fraudsters
        attempting multiple transactions with different cards using the same
        device, common practice with the unscrupulous.
      </p>
    </StyledDiv>

    <StyledDiv className="flex flex-col items-center rounded-md pt-8 pb-12 px-4">
      <StaticImage
        src="../../images/3d-secure.png"
        width={250}
        quality={95}
        placeholder="tracedSVG"
        formats={["AUTO", "WEBP", "AVIF"]}
        alt="3D Secure 2"
      />
      <h2 className={h2Style}>3D Secure 2</h2>
      <p className={pStyle}>
        Developed by Visa and Mastercard, 3D secure 2 protects against
        fraudulent purchases and provides secure, frictionless checkout
        authentication that decreases cart abandonment rates.
      </p>
    </StyledDiv>

    <StyledDiv className="flex flex-col items-center rounded-md pt-8 pb-12 px-4">
      <StaticImage
        src="../../images/global-monitoring-2.png"
        width={288}
        quality={95}
        placeholder="tracedSVG"
        formats={["AUTO", "WEBP", "AVIF"]}
        alt="Global Monitoring"
      />
      <h2 className={h2Style}>Global Monitoring</h2>
      <p className={pStyle}>
        Our 24x7, real-time global transaction monitoring protects you against
        fraudulent card transactions with suspicious behaviour reported and
        added to blacklists.
      </p>
    </StyledDiv>
  </div>
);

export default SecurityFeatures;
