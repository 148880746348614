import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

const SecurityPCI = () => (
  <div className="relative w-full mt-32 xl:mt-48">
    <div className="w-full max-w-screen-xl flex items-start justify-center mx-auto mb-24 px-6 xl:px-14">
      <div className="flex flex-col-reverse lg:flex-row items-center">
        <div className="w-full lg:w-1/2 xl:pl-6 2xl:pl-0">
          <h2 className="text-3xl md:text-5xl font-bold mb-12 md:mb-16">
            PCI DSS Compliant
          </h2>
          <p className="text-lg md:text-xl leading-relaxed md:leading-loose md:px-2">
            The PCI Security Standards Council is a global organisation formed
            by major credit card companies, including Visa, MasterCard,
            Discover, and American Express. PCI compliance is mandatory for
            businesses that allow credit card payments and ensures your
            customer's credit and debit card data is kept safe and secure.
          </p>
        </div>
        <div className="w-full lg:w-1/2 pb-6 md:px-12 xl:p-6">
          <StaticImage
            src="../../images/fci-dss-compliant.png"
            width={648}
            quality={95}
            placeholder="tracedSVG"
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="PCI DSS Compliant"
          />
        </div>
      </div>
    </div>
  </div>
);

export default SecurityPCI;
