import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

const ServiceOnlinePayment = () => (
  <div id="services" className="relative w-full mt-24 md:mt-32 xl:mt-24">
    <div className="w-full max-w-screen-xl flex items-start justify-center mx-auto mb-24 px-6 xl:px-14">
      <div className="flex flex-col lg:flex-row items-center">
        <div className="hidden md:block w-full lg:w-1/2 pb-6 md:px-12 xl:p-6 xl:mt-24">
          <StaticImage
            src="../../images/online-payment-integration.png"
            width={650}
            quality={95}
            placeholder="tracedSVG"
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="Online Payment Integration"
          />
        </div>
        <div className="md:hidden w-full lg:w-1/2 pb-6 md:px-12 xl:p-6">
          <StaticImage
            src="../../images/mobile-online-payment-integration.png"
            width={531}
            quality={95}
            placeholder="tracedSVG"
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="Online Payment Integration"
          />
        </div>
        <div className="w-full lg:w-1/2 xl:pr-6 2xl:pr-0">
          <h2 className="text-3xl md:text-5xl font-bold mb-12 md:mb-16 xl:mt-24">
            Online Payment Integration
          </h2>
          <p className="text-lg md:text-xl leading-relaxed md:leading-loose md:px-2">
            Fishbulb Pay provides an elegant, branded, customer-friendly
            checkout process offering a wide range of payment options, including
            Visa, MasterCard, Amex, Diners, WeChat Pay, Union Pay, ApplePay and
            GooglePay, to name a few. Our payment gateway API can easily be
            configured and integrated with any website, mobile app, or web app.
          </p>
        </div>
      </div>
    </div>

    <div className="hidden 2xl:block absolute bottom-0 right-0 -mb-32">
      <StaticImage
        src="../../images/side-2.png"
        width={292}
        quality={95}
        placeholder="tracedSVG"
        formats={["AUTO", "WEBP", "AVIF"]}
        alt=""
      />
    </div>
  </div>
);

export default ServiceOnlinePayment;
