const menuItems = [
  {
    path: "home",
    label: "Home",
  },
  {
    path: "services",
    label: "Services",
  },
  {
    path: "security",
    label: "Security",
  },
  {
    path: "pricing",
    label: "Pricing",
  },
];

export default menuItems;
