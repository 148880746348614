/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react";
import PropTypes from "prop-types";

import Header from "./header/header";

const Layout = ({ children }) => (
  <>
    <Header />
    <div>
      <main>{children}</main>
      <footer className="w-full bg-site-blue-heavy py-24 px-4">
        <p className="text-white text-center text-lg uppercase font-bold">
          <a href="tel:02-9003-1015">PHONE: (02) 9003 1015</a>
        </p>
        <p className="text-white text-center text-lg uppercase font-bold">
          © Copyright {new Date().getFullYear()} Fishbulbpay.
          <br className="lg:hidden" /> All Rights Reserved
        </p>
      </footer>
    </div>
  </>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
