import * as React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

const Logo = () => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <div>
      <h1>
        <Link to="/">
          <StaticImage
            src="../images/fishbulbpay-logo.png"
            width={278}
            quality={95}
            placeholder="tracedSVG"
            formats={["AUTO", "WEBP", "AVIF"]}
            alt={data.site.siteMetadata?.title || `Title`}
          />
        </Link>
      </h1>
    </div>
  );
};

export default Logo;
