import React, { useState, useEffect } from "react";
import { Link } from "react-scroll";
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";
import styled from "styled-components";
import { MenuIcon } from "@heroicons/react/outline";

import Logo from "../logo";
import menuItems from "./header.data";

const MobileDrawer = loadable(
  () => pMinDelay(import("./mobile-drawer"), 2000),
  {
    fallback: (
      <div className="w-2/3 xl:hidden flex justify-end">
        <button className="text-site-gray focus:outline-none focus:ring-2 focus:ring-white bg-transparent hover:bg-transparent p-0">
          <span className="sr-only">Open panel</span>
          <MenuIcon className="h-6 w-6" aria-hidden="true" />
        </button>
      </div>
    ),
  }
);

const StyledNav = styled.nav`
  a.active span {
    color: #00b3e6;
  }
`;

const Header = () => {
  const [scrolled, setScrolled] = useState(false);

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 50) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  });

  return (
    <div
      className={`${
        scrolled ? "sticky top-0 bg-white border-b" : "bg-transparent"
      } w-full z-40`}
    >
      <header
        className={`${
          scrolled ? "py-4" : "my-8"
        } w-full max-w-screen-xl flex items-center justify-between mx-auto px-6 xl:px-14`}
      >
        <Logo />

        <StyledNav className="hidden xl:flex items-center">
          {menuItems.map(({ path, label }, i) => (
            <Link
              activeClass="active"
              to={path}
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              key={i}
            >
              <span className="text-base text-site-gray font-bold uppercase mx-6 cursor-pointer hover:text-site-blue-dark">
                {label}
              </span>
            </Link>
          ))}

          <Link
            to="contact"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
          >
            <button className={`${scrolled ? "py-4" : null} ml-6`}>
              Contact Us Today
            </button>
          </Link>
        </StyledNav>

        <MobileDrawer />
      </header>
    </div>
  );
};

export default Header;
