import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import styled from "styled-components";

const StyledDiv = styled.div`
  background-image: linear-gradient(#edfbff, #ffffff);
`;

const Security = () => (
  <div className="relative w-full mb-32">
    <div className="w-full -mb-2">
      <StaticImage
        src="../../images/waves.png"
        width={1923}
        quality={95}
        layout="fullWidth"
        placeholder="tracedSVG"
        formats={["AUTO", "WEBP", "AVIF"]}
        alt=""
      />
    </div>
    <StyledDiv id="security">
      <div className="w-full lg:w-1/2 flex flex-col items-center mx-auto mb-24 pt-32 px-6 lg:px-0">
        <h2 className="text-6xl font-bold mb-16 text-center">Security</h2>
        <p className="text-lg md:text-xl leading-relaxed md:leading-loose md:px-2 text-center">
          Protecting your payment data is our top priority. We store, process
          and transmit with end-to-end encryption on a secure network. We're
          also Data Security Standard (PCI DSS) certified and have complete
          fraud and risk management solutions built into our payment
          infrastructure.
        </p>
      </div>
    </StyledDiv>
  </div>
);

export default Security;
