import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Hero from "../components/sections/hero"
import Services from "../components/sections/services"
import ServiceOnlinePayment from "../components/sections/service-online-payment"
import ServicePOS from "../components/sections/service-pos"
import ServiceAccounting from "../components/sections/service-accounting"
import Security from "../components/sections/security"
import SecurityPCI from "../components/sections/security-pci"
import SecurityFraud from "../components/sections/security-fraud"
import SecurityFeatures from "../components/sections/security-features"
import Pricing from "../components/sections/pricing"
import DevBundles from "../components/sections/dev-bundles"
import Contact from "../components/sections/contact"
import ServicesMobile from "../components/sections/services-mobile"

const IndexPage = () => (
  <Layout>
    <Seo title="Secure Payment Gateway" />
    <Hero />
    <Services />
    <ServicesMobile />
    <ServiceOnlinePayment />
    <ServicePOS />
    <ServiceAccounting />
    <Security />
    <SecurityPCI />
    <SecurityFraud />
    <SecurityFeatures />
    <Pricing />
    <DevBundles />
    <Contact />
  </Layout>
)

export default IndexPage
