import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

const ServiceAccounting = () => (
  <div className="relative w-full mt-32 xl:mt-0">
    <div className="w-full max-w-screen-xl flex items-start justify-center mx-auto mb-24 px-6 xl:px-14">
      <div className="flex flex-col lg:flex-row items-center">
        <div className="w-full lg:w-1/2 pb-6 md:px-12 xl:p-6">
          <StaticImage
            src="../../images/accounting-crm-erp-integration.png"
            width={572}
            quality={95}
            placeholder="tracedSVG"
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="Accounting, CRM & ERP Integration"
          />
        </div>
        <div className="w-full lg:w-1/2 xl:pr-6 2xl:pr-0">
          <h2 className="text-3xl md:text-5xl font-bold mb-12 md:mb-16">
            Accounting, CRM & ERP Integration
          </h2>
          <p className="text-lg md:text-xl leading-relaxed md:leading-loose md:px-2">
            We integrate your payment systems to automate communication between
            your accounting, CRM, ERP and other business software systems to
            reduce your labour costs, eliminates data entry errors whilst
            automatically keeping your accounting system up-to-date. Passing
            your data to your accounting platform using tokenisation and
            end-to-end encryption ensures PCI DSS Compliance.
          </p>
          
        </div>
      </div>
    </div>

    <div className="hidden 2xl:block absolute bottom-0 left-0 mb-20">
      <StaticImage
        src="../../images/side-3.png"
        width={312}
        quality={95}
        placeholder="tracedSVG"
        formats={["AUTO", "WEBP", "AVIF"]}
        alt=""
      />
    </div>
  </div>
);

export default ServiceAccounting;
