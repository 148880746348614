import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";

const SecurityFraud = () => (
  <div className="relative w-full mt-32 xl:mt-48">
    <div className="w-full max-w-screen-xl flex items-start justify-center mx-auto mb-24 px-6 xl:px-14">
      <div className="flex flex-col lg:flex-row items-center">
        <div className="w-full lg:w-1/2 pb-6 md:px-12 xl:p-6">
          <StaticImage
            src="../../images/fraud-risk-management.png"
            width={569}
            quality={95}
            placeholder="tracedSVG"
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="Fraud & Risk Management"
          />
        </div>
        <div className="w-full lg:w-1/2 xl:pr-6 2xl:pr-0">
          <h2 className="text-3xl md:text-5xl font-bold mb-12 md:mb-16">
            Fraud & Risk Management
          </h2>
          <p className="text-lg md:text-xl leading-relaxed md:leading-loose md:px-2 mb-6">
            Preventing the unrelenting threat of fraud in today's payments world
            means employing intelligent, real-time, multi-tiered protection
            tailored to the needs of eCommerce & mCommerce merchants.
          </p>
          <p className="text-lg md:text-xl leading-relaxed md:leading-loose md:px-2 mb-6">
            Our intelligent fraud detection and prevention solution delivered as
            a managed service through an expert team pf payments risk analysts
            provides instant decisions (accept/challenge/deny) on eCommerce and
            mCommerce transactions.
          </p>
          <p className="text-lg md:text-xl leading-relaxed md:leading-loose md:px-2">
            Our Risk Management solution also has integrations into
            organisations such a ReD and ThreatMetrix for added risk mitigation.
          </p>
        </div>
      </div>
    </div>
  </div>
);

export default SecurityFraud;
