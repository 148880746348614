import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";

const DevBundles = () => (
  <div className="relative w-full mt-24 xl:mt-32">
    <div className="w-full max-w-screen-xl flex items-start justify-center mx-auto mb-24 px-6 xl:px-14">
      <div className="flex flex-col-reverse lg:flex-row items-center">
        <div className="w-full lg:w-1/2 xl:pl-6 2xl:pl-0">
          <h2 className="text-3xl md:text-5xl font-bold mb-12 md:mb-16">
            Development Bundles
          </h2>
          <p className="text-lg md:text-xl leading-relaxed md:leading-loose md:px-2 mb-6">
            Put your merchant fees to work with our software development
            bundles.
          </p>
          <p className="text-lg md:text-xl leading-relaxed md:leading-loose md:px-2 mb-6">
            Our development team builds custom CRM, ERP, business applications,
            ecommerce websites and mobile apps. If your merchant fee spend is
            large enough we can drastically reduce or even eliminate your
            software development costs.
          </p>
          <p className="text-lg md:text-xl leading-relaxed md:leading-loose md:px-2">
            Please speak with one of our consultants to find out more.
          </p>
        </div>
        <div className="w-full lg:w-1/2 pb-6 md:px-12 xl:p-6">
          <StaticImage
            src="../../images/development-bundles.png"
            width={672}
            quality={95}
            placeholder="tracedSVG"
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="PCI DSS Compliant"
          />
        </div>
      </div>
    </div>
  </div>
);

export default DevBundles;
