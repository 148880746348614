import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "react-scroll";
import ReactTypingEffect from "react-typing-effect";
import styled from "styled-components";

const StyledContainer = styled.div`
  @media (min-width: 1280px) {
    min-height: 700px;
  }
  @media (min-width: 1440px) {
    min-height: 800px;
  }
  @media (min-width: 1920px) {
    min-height: 1000px;
  }
`;

const StyledDiv1 = styled.div`
  @media (max-width: 1440px) {
    width: 850px;
  }
  @media (max-width: 1280px) {
    width: 750px;
  }
`;

const StyledH2 = styled.h2`
  font-size: 50px;

  @media (max-width: 768px) {
    font-size: 30px;
  }
`;

const StyledDivMobile = styled.div`
  @media (width: 1024px) {
    width: 100%;
    margin-left: 400px;
    margin-top: -50px;
  }
  @media (max-width: 1023px) {
    width: 1000px;
    margin-left: 270px;
  }
  @media (max-width: 767px) {
    width: 900px;
    margin-left: 80px;
    margin-top: -60px;
  }
`;

const Hero = () => (
  <div id="home">
    <div className="xl:hidden absolute top-0 right-0 w-full overflow-x-hidden -z-10">
      <StyledDivMobile>
        <StaticImage
          src="../../images/mobile-hero-side.png"
          width={1215}
          quality={95}
          placeholder="tracedSVG"
          formats={["AUTO", "WEBP", "AVIF"]}
          alt=""
        />
      </StyledDivMobile>
    </div>

    <StyledContainer className="w-full flex flex-col items-center justify-center">
      <StyledDiv1 className="hidden xl:block absolute top-0 right-0 -z-10">
        <StaticImage
          src="../../images/secure-payment-gateway.png"
          width={1043}
          quality={95}
          placeholder="tracedSVG"
          formats={["AUTO", "WEBP", "AVIF"]}
          alt=""
        />
      </StyledDiv1>

      <div className="w-full max-w-screen-xl flex flex-col justify-center mx-auto mt-40 md:mt-24 xl:mt-0 px-4 md:px-0">
        <div className="flex flex-col items-center xl:items-start mb-16 xl:mb-24 xl:mx-16">
          <StyledH2 className="font-extrabold uppercase text-site-blue-heavy text-center xl:text-left leading-tight">
            Custom Integrated
            <br />
            Payment Solutions
            <br />
            For Your Business
            <br />
          </StyledH2>
          <h3 className="text-2xl font-semibold text-site-gray text-center xl:text-left my-12">
            <ReactTypingEffect
              speed={20}
              eraseSpeed={20}
              eraseDelay={2000}
              typingDelay={1000}
              text={[
                "Secure payment gateway",
                "Competitive rates",
                "EFTPOS terminals",
                "Custom POS software",
                "Online checkouts",
                "Same day settlement",
                "PCI DSS compliant",
              ]}
            />
          </h3>
          <Link
            to="services"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
          >
            <button>Discover More</button>
          </Link>

          <div className="md:w-8/12 xl:hidden mt-12 mx-auto">
            <StaticImage
              src="../../images/mobile-hero-img.png"
              width={886}
              quality={95}
              placeholder="tracedSVG"
              formats={["AUTO", "WEBP", "AVIF"]}
              alt=""
            />
          </div>
        </div>

        <div className="hidden 2xl:block absolute bottom-0 left-0 -mb-24 -z-10 2xl:w-full">
          <StaticImage
            src="../../images/side-1.png"
            width={296}
            quality={95}
            placeholder="tracedSVG"
            formats={["AUTO", "WEBP", "AVIF"]}
            alt=""
          />
        </div>
      </div>
    </StyledContainer>
  </div>
);

export default Hero;
