import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";

const h2Style = "text-lg lg:text-2xl uppercase text-center font-extrabold";

const ServicesMobile = () => (
  <div className="md:hidden w-full max-w-screen-xl flex flex-col items-center px-6 mx-auto mt-12">
    <div className="relative -mb-1">
      <StaticImage
        src="../../images/mobile-service-payments.png"
        width={419}
        quality={95}
        placeholder="tracedSVG"
        formats={["AUTO", "WEBP", "AVIF"]}
        alt="Online Payment Integration"
      />
      <div className="absolute bottom-0 w-full">
        <h2 className={h2Style}>
          Online Payment
          <br /> Integration
        </h2>
      </div>
    </div>

    <div className="relative -mb-1">
      <StaticImage
        src="../../images/mobile-service-pos.png"
        width={419}
        quality={95}
        placeholder="tracedSVG"
        formats={["AUTO", "WEBP", "AVIF"]}
        alt="POS & EFTPOS Integration"
      />
      <div className="absolute bottom-0 w-full">
        <h2 className={h2Style}>
          POS & EFTPOS
          <br /> Integration
        </h2>
      </div>
    </div>

    <div>
      <StaticImage
        src="../../images/mobile-service-accounting.png"
        width={419}
        quality={95}
        placeholder="tracedSVG"
        formats={["AUTO", "WEBP", "AVIF"]}
        alt="Accounting, CRM & ERP Software Integration"
      />
      <h2 className={h2Style}>
        Accounting, CRM & ERP
        <br /> Software Integration
      </h2>
    </div>
  </div>
);

export default ServicesMobile;
