import React, { useState } from "react";
import { Switch } from "@headlessui/react";
import { Link } from "react-scroll";
import { StaticImage } from "gatsby-plugin-image";
import styled from "styled-components";

const StyledDiv1 = styled.div`
  height: 638px;
  background: transparent;
  background-image: radial-gradient(#00b3e6 1px, transparent 0);
  background-size: 28px 28px;
  background-position: -19px -19px;

  @media (max-width: 1024px) {
    height: auto;
  }

  @media (max-width: 768px) {
    background-size: 20px 20px;
    background-position: -30px -30px;
  }
`;

const StyledDiv2 = styled.div`
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.1);
`;

const StyledDiv3 = styled.div`
  background: rgba(255, 255, 255, 0.7);
  width: 783px;
`;

const priceWrapper = "w-full border-b pt-4 pb-8 mb-4";
const h2Style = "text-base md:text-lg text-center font-bold mb-3 px-2";
const h3Style1 =
  "text-4xl md:text-5xl text-site-blue-dark text-center font-bold px-2";
const detailsWrapper = "flex flex-col items-center pt-8 pb-10 px-2";
const h3Style2 =
  "text-sm text-center font-extrabold text-gray-500 uppercase mb-4";
const pStyle = "text-base text-center leading-loose mb-2 px-2";
const buttonStyle =
  "border-2 border-site-blue-dark text-bold text-site-blue-dark hover:text-white bg-white hover:bg-site-blue-dark capitalize mt-6 mb-4 py-4 px-12";

const Pricing = () => {
  const [international, setInternational] = useState(false);

  return (
    <div id="pricing" className="relative w-full mb-32 mt-12">
      <div className="hidden 2xl:block absolute top-0 left-0 -mt-16 -z-10">
        <StaticImage
          src="../../images/side-4.png"
          width={247}
          quality={95}
          placeholder="tracedSVG"
          formats={["AUTO", "WEBP", "AVIF"]}
          alt=""
        />
      </div>

      <div>
        <div className="w-1/2 flex flex-col items-center justify-center mx-auto mb-28 pt-8">
          <h2 className="text-6xl font-bold mb-8 mt-24 text-center">Pricing</h2>

          <div className="flex items-center">
            <h2
              className={`${
                international ? "font-normal text-gray-400" : "font-semibold"
              } text-xl`}
            >
              Domestic
            </h2>
            <Switch
              checked={international}
              onChange={setInternational}
              className="bg-site-blue-dark relative inline-flex flex-shrink-0 h-9 w-20 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 mx-6 px-0 py-0"
            >
              <span className="sr-only">Choose option</span>
              <span
                aria-hidden="true"
                className={`${
                  international ? "translate-x-11" : "translate-x-0"
                }
                  pointer-events-none inline-block h-8 w-8 rounded-full bg-white shadow-lg transform ring-0 transition ease-in-out duration-200`}
              />
            </Switch>
            <h2
              className={`${
                international ? "font-semibold" : "font-normal text-gray-400"
              } text-xl`}
            >
              International
            </h2>
          </div>
        </div>
      </div>

      <StyledDiv1 className="xl:ml-2 z-10 pb-12">
        <div className="w-full max-w-screen-md grid-cols-1 md:grid-cols-2 gap-6 xl:gap-12 mx-auto px-6 ">
          <StyledDiv2 className="flex flex-col items-center bg-white rounded-md pt-8 -mt-16 xl:-mt-12 z-20">
            <div className={priceWrapper}>
              <h2 className={h2Style}>Standard Online Rates</h2>
              <h3 className={h3Style1}>
                {`${international ? "2.9%" : "1.75%"}`} + $0.30
              </h3>
            </div>
            <div className={detailsWrapper}>
              <h3 className={h3Style2}>Ask us about...</h3>
              <p className={pStyle}>Volume Discounts</p>
              <p className={pStyle}>Charity Discounts</p>
              <p className={pStyle}>Education Discounts</p>
              <Link
                to="contact"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
                <button className={buttonStyle}>Apply Now</button>
              </Link>
            </div>
          </StyledDiv2>
          
        </div>

        <StyledDiv3 className="hidden lg:flex flex-col items-center mx-auto mt-14 z-10">
          <h2 className="text-lg uppercase text-site-gray font-medium mb-8 z-20">
            We accept payments through
          </h2>
          <div className="z-20">
            <StaticImage
              src="../../images/payment-logos-full.png"
              width={782}
              quality={95}
              placeholder="tracedSVG"
              formats={["AUTO", "WEBP", "AVIF"]}
              alt=""
            />
          </div>
        </StyledDiv3>
      </StyledDiv1>

      <div className="lg:hidden flex flex-col items-center mx-auto mt-14 px-6">
        <h2 className="text-lg uppercase text-site-gray font-medium mb-8">
          We accept payments through
        </h2>
        <div>
          <StaticImage
            src="../../images/payment-logos-full-col.png"
            width={372}
            quality={95}
            placeholder="tracedSVG"
            formats={["AUTO", "WEBP", "AVIF"]}
            alt=""
          />
        </div>
      </div>

      <div className="hidden 2xl:block absolute bottom-0 right-0 mb-24 -z-10">
        <StaticImage
          src="../../images/side-5.png"
          width={249}
          quality={95}
          placeholder="tracedSVG"
          formats={["AUTO", "WEBP", "AVIF"]}
          alt=""
        />
      </div>
    </div>
  );
};

export default Pricing;
